// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {FacilityAccessibilityProduct4} from "../product.facility/model/FacilityAccessibilityProduct4";

export const environment: IProductEnvironment = {

  name: 'Failte Ireland: Facilities',
  filename: 'environment.facilities-70768473.ts',

  isDevelopment: false,
  isProduction: true,
  isTest: false,

  authenticationConfig: {

    emailLink: false,
    textMessage: false,
    usernamePassword: true,
    google: false
  },

  firebaseConfig: {
    apiKey: "AIzaSyC405PJr2KfFr2vuz2ymCza8TFsENBuU-E",
    authDomain: "facilities-70768473.firebaseapp.com",
    databaseURL: "https://facilities-70768473-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "facilities-70768473",
    storageBucket: "facilities-70768473.appspot.com",
    messagingSenderId: "903513622478",
    appId: "1:903513622478:web:3c03c62c02b1f05bf0cd9f"
  },

  // curl -iLS https://us-central1-$_TARGET_ENV.cloudfunctions.net/public/functions/info
  cloudFunctionsUrl: 'https://us-central1-facilities-70768473.cloudfunctions.net',

  nocoDbConfig: {
    // httpServer: 'http://localhost:8080',
    // httpServer: 'http://spot.local:8080',
    httpServer: 'https://mobilitymojo-spot.com',
    user: 'test@mobilitymojo.com',
    password: 'test@mobilitymojo.com',
    authorization: 'Basic bW9iaWxpdHk6bW9qbw==',
    // projectTitle: 'products-playpen',
    projectTitle: '2024 - Grosvenor and The Crown Estate',
  },

  productConfig: {

    firebaseAnswersRoot: '/answers-x/facilities',
    // product:  ProductHelper.cloneProduct( new FacilityAccessibilityProduct(), true )
    product:  FacilityAccessibilityProduct4.INSTANCE,
    productType: EProductType.facilities,
    canCommentOnClusters: true,
    canEditClusters: false,
    clusterSupport: true,

  },




};
